import * as React from "react";
import FormComponent from "./dbFormComp";

const FormLayout = (props) => {
    return (
        <>
            <div className="text-center">
                <div className={`sm:px-2 md:max-w-sm m-auto`}>
                    <div className={"text-center font-bold text-bold text-lg text-sm text-primary-gray mt-2 lg:block hidden"}>
                    </div>
                    <FormComponent
                        formName={"topPropertiesBelow250k"}
                        mandatoryField={true}
                        countryISO={props.countryISO}
                        buttonText={"Download"}
                        showWhatsappButton={true}
                    />

                </div>
            </div>
        </>
    );
};

export default FormLayout;
