import {headerSection, mainSection, formBox} from "./heroArea.module.css";
import FormLayout from "./form";
import * as React from "react";
import {StaticImage} from "gatsby-plugin-image";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { loaderText } from "../fiveJBR/form.module.css";
// import { ImSpinner } from "react-icons/im";
// import ModalPage from "../modalPage1";
import {contentDiv, formBoxAbs} from "../../styles/sobha.module.css";
// import Connectivity from "./connectivities";
// import Eminity from "./eminity";
// import StickyForm from "./stickyForm";
import HeroText from "./herotext";
import StickyBox from "react-sticky-box";
import "./central-park.css";

const HeroArea = ({countryISO, short, symbol, downloadType, onFormOpen}) => {
    const [loading, setLoading] = React.useState(false);

    return (
        <>
            <div className={mainSection}>
                <section
                    className={`${headerSection} text-center bg-center md:bg-center font-lato grid-cols-12`}
                >
                    <div className={"centralParkHead"}>
                    </div>

                    <div className={"md:hidden bg-white pt-4 px-4 block pb-2"}>
                        <div className={`bg-white`}>

                            <h4 className={'pb-2 text-left text-gray-700'}>
                                Bricks n Woods Real Estate
                            </h4>
                            <p className='text-justify text-primary-lightGrayTxt text-xs opacity-60'>By submitting this form, you
                                confirm your
                                interest in purchasing property in Dubai. Kindly provide accurate contact information to
                                allow our representative get in touch as soon as possible.</p>
                            <FormLayout countryISO={countryISO} mandatoryitems={true}/>
                        </div>
                    </div>
                </section>
                <div className={`${formBoxAbs} hidden md:block`}>
                    <div className={`${contentDiv}`}>
                        <div className="grid grid-cols-12 gap-1">
                            <div className="col-span-8 lg:col-span-8 lg:pr-16"></div>

                            <div className="col-span-4 hidden lg:block">
                                <StickyBox offsetTop={20} offsetBottom={20}>
                                    <div
                                        className={`hidden md:block grid-cols-4 bg-white p-1 mt-8 mx-auto  py-10 ${formBox}`}
                                    >
                                        <FormLayout countryISO={countryISO}/>
                                    </div>
                                </StickyBox>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default HeroArea;
