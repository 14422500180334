// extracted by mini-css-extract-plugin
export var mainSection = "heroArea-module--mainSection--6p5us";
export var headerSection = "heroArea-module--headerSection--3paIQ";
export var topSection = "heroArea-module--topSection--3iJ89";
export var bottomUnderLine = "heroArea-module--bottomUnderLine--1-umE";
export var footerList = "heroArea-module--footerList--jYEyM";
export var heroText = "heroArea-module--heroText--3X8iH";
export var crestList = "heroArea-module--crestList--3QqM0";
export var sobhaHeading = "heroArea-module--sobhaHeading--3JMbs";
export var sliderFooterContainer = "heroArea-module--sliderFooterContainer--3rEJm";
export var sliderFooter = "heroArea-module--sliderFooter--w1s2S";
export var videoContainer = "heroArea-module--videoContainer--3ghjX";
export var gridBox = "heroArea-module--gridBox--MSQNf";
export var firstBlock = "heroArea-module--firstBlock--257ql";
export var gridBox2 = "heroArea-module--gridBox2--3A3fO";
export var area = "heroArea-module--area--2auP2";
export var price = "heroArea-module--price--3uMPp";
export var downloadBtn = "heroArea-module--downloadBtn--3ocAa";
export var firstGrid = "heroArea-module--firstGrid--2Mvjn";
export var gridText = "heroArea-module--gridText--Dxq3N";
export var minTextClass = "heroArea-module--minTextClass--2tezO";
export var addressBlock = "heroArea-module--addressBlock--2N5GG";
export var formBox = "heroArea-module--formBox--1GLWM";